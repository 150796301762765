import {
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';
import { routes } from './routes/app.routes';
import { environment } from '../environments/handyhand/environment.dk';
import {
  provideRouter,
  withViewTransitions,
  withComponentInputBinding,
  withInMemoryScrolling,
  withRouterConfig,
} from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideClarity } from 'ngx-clarity';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideToastr } from 'ngx-toastr';

import {
  provideNgxWebstorage,
  withLocalStorage,
  withNgxWebstorageConfig,
} from 'ngx-webstorage';
import { GrowthBookModule, GrowthBookService } from './growthbook';
import { TranslateModule } from '@ngx-translate/core';
import { routingConfiguration } from '@app/routes/seo.routes';
import {
  provideClientHydration,
  withHttpTransferCacheOptions,
} from '@angular/platform-browser';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideNgxWebstorage(
      withNgxWebstorageConfig({ separator: ':', caseSensitive: true }),
      withLocalStorage(),
    ),
    provideRouter(
      routes,
      withViewTransitions(),
      withComponentInputBinding(),
      withInMemoryScrolling({ scrollPositionRestoration: 'top' }),
      withRouterConfig(routingConfiguration),
    ),
    /* provideClientHydration(
      withHttpTransferCacheOptions({
        includePostRequests: true,
      }),
    ),*/
    provideHttpClient(withFetch()),
    provideClarity({
      enabled: environment.production && environment.env === 'production',
      projectId: environment.clarityId,
    }),
    importProvidersFrom(
      TranslateModule.forRoot({}),
      GrowthBookModule.forRoot(),
    ),
    GrowthBookService,
    provideAnimations(),
    provideToastr(),
  ],
};
