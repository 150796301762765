import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { GrowthBookService } from './growthbook.service';
import { StorageService } from '@app/services/logic/storage.service';
import { EventService } from '@app/services/logic/event.service';
import { context } from './context';

export function setupGrowthbookFactory(growthbookService: GrowthBookService) {
  return async () => {
    return growthbookService.init(context);
  };
}

// TODO: abstract into separate package to avoid duplicate code between web and seo projects + happyhelper
@NgModule({
  providers: [GrowthBookService, StorageService, EventService],
})
export class GrowthBookModule {
  static forRoot(): ModuleWithProviders<GrowthBookModule> {
    return {
      ngModule: GrowthBookModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: setupGrowthbookFactory,
          multi: true,
          deps: [GrowthBookService, StorageService, EventService],
        },
      ],
    };
  }
}
