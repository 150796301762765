<div class="categories_tab">
  <p class="link">{{ 'GENERAL.BTN.CATS' | translate}}</p>
  <div class="dropdown_menu_wrapper">
    <div class="left_wrapper">
      <p>{{ 'GENERAL.BTN.CATS' | translate}}</p>
      <span>{{ 'GENERAL.SEL_TASK_TYPE' | translate}}</span>

      <div class="as_to_wrap">
        <p class="as_to_text1">{{ 'GENERAL.AS_TO' | translate}}</p>
        <p class="as_to_text2">{{ 'GENERAL.HELP_FOR_TXT' | translate}}</p>
      </div>
    </div>
    <ul class="desktop_cat_wrapper">
      @for (link of verticalsLinks; track $index) {
      <li>
        <a routerLink="/{{link[1]}}/{{environment.seo.service}}">{{link[0]}}</a>
      </li>
      }
      <li>
        <a
          class="last_link"
          routerLink="/{{ 'NAVIGATION.URL.CATEGORIES' | translate}}"
          >{{ 'GENERAL.BTN.SEE_ALL' | translate}}</a
        >
      </li>
    </ul>
  </div>
</div>

<mat-menu class="categories_menu" #menu="matMenu">
  @for (link of verticalsLinks; track $index) {
  <a mat-menu-item routerLink="/{{link[1]}}/{{environment.seo.service}}">
    {{link[0]}}
  </a>
  }
  <a
    mat-menu-item
    class="see_more_cats"
    routerLink="/{{ 'NAVIGATION.URL.CATEGORIES' | translate}}"
    >{{ 'GENERAL.BTN.SEE_MORE' | translate}}</a
  >
</mat-menu>
