import { Inject, Injectable, PLATFORM_ID, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { EventService } from '@services/logic/event.service';
import { StorageService } from '@app/services/logic/storage.service';
import {
  GrowthBook,
  Context,
  Attributes,
  RefreshFeaturesOptions,
  FeatureResult,
} from '@growthbook/growthbook';
import { environment } from '../../../environments/handyhand/environment.dk';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class GrowthBookService implements OnDestroy {
  private destroy$ = new Subject<void>();
  constructor(
    private storage: StorageService,
    private eventService: EventService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}
  growthBook!: GrowthBook;
  private growthBookSource = new Subject();
  private eventCount = 0;

  async init(config?: Context) {
    if (!isPlatformBrowser(this.platformId)) return;
    this.growthBook = new GrowthBook({
      enableDevMode: environment.env !== 'production',
      subscribeToChanges: true,
      backgroundSync: true,
      trackingCallback: (experiment, result) => {
        const gbuuid = this.storage.getGrowthbookUUID();
        const user_id = this.storage?.user?.id || undefined;
        const user = this.storage?.user || undefined;
        const experimentTrackingData = {
          gbuuid,
          user_id,
          event: 'experiment_viewed',
          experiment_id: experiment.key,
          variation_id: result.key,
        };
        this.eventService.customEventGrowthbookExperiment(
          experimentTrackingData,
          user,
        );
      },
      onFeatureUsage: (featureKey, result) => {
        if (environment.env !== 'production') {
          console.log(`
            Feature '${featureKey}' used.
            Result: ${JSON.stringify(result, null, 2)}
          `);
        }
      },
      attributes: {
        gbuuid: this.storage?.getGrowthbookUUID(),
        user_id: this.storage?.user?.id,
        url: this.router?.url,
      },
      ...config,
    });

    try {
      await this.growthBook.loadFeatures();
    } catch (err) {
      console.log('Load features Growthbook error', err);
    }

    this.growthBook.setRenderer(() => {
      this.triggerUpdate();
    });
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  triggerUpdate(): void {
    this.growthBookSource.next(this.eventCount++);
  }

  subscribe(callback: (n: number) => void) {
    callback(0);
    return this.growthBookSource.pipe(takeUntil(this.destroy$)).subscribe({
      next(value) {
        callback(value as number);
      },
    });
  }

  public loadFeatures(): Promise<void> {
    return this.growthBook.loadFeatures({ skipCache: true });
  }

  public isOn(featureKey: string): boolean {
    return this.growthBook.isOn(featureKey);
  }

  public isOff(featureKey: string): boolean {
    return this.growthBook.isOff(featureKey);
  }

  public getFeatureValue(featureKey: string, defaultValue: any): any {
    return this.growthBook.getFeatureValue(featureKey, defaultValue);
  }

  /**
   * In addition to the isOn and getFeatureValue helper methods,
   * there is the evalFeature method that gives you more detailed
   * information about why the value was assigned to the user.
   * @param featureKey
   * @returns
   */
  public evalFeature(featureKey: string): FeatureResult<any> {
    return this.growthBook.evalFeature(featureKey);
  }

  /**
   * Completely replaces attributes
   * @param attributes
   */
  public setAttributes(attributes: Attributes): void {
    this.growthBook.setAttributes(attributes);
  }

  public updateAttributes(attributes: Attributes): void {
    const existingAttributes = this.getAttributes();
    this.growthBook.setAttributes({
      ...existingAttributes,
      ...attributes,
    });
  }

  public refreshFeatures(options?: RefreshFeaturesOptions): Promise<void> {
    return this.growthBook.refreshFeatures(options);
  }

  public setURL(url: string) {
    return this.growthBook.setURL(url);
  }

  public getAttributes() {
    return this.growthBook.getAttributes();
  }

  public getFeatures() {
    return this.growthBook.getFeatures();
  }

  public getExperiments() {
    return this.growthBook.getExperiments();
  }
}
