import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/root/app.component';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
