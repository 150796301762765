import { environment } from '../../environments/handyhand/environment.dk';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { CommonModule } from '@angular/common';
import {
  ActivatedRoute,
  Event,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';

import { HeaderComponent } from '@components/header/header.component';

import { EventService } from '@services/logic/event.service';
import { GrowthBookService } from '@app/growthbook';
import { StorageService } from '@services/logic/storage.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, HeaderComponent, RouterOutlet],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private adClickIds = [
    'gclid',
    'wbraid',
    'gbraid',
    'msclkid',
    'fbclid',
    'SsCid',
    'TTCLID',
    'twclid',
  ];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private growthbook: GrowthBookService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private storage: StorageService,
    private eventService: EventService,
  ) {
    if (isPlatformBrowser(platformId)) {
      this.router.events
        .pipe(takeUntil(this.destroy$))
        .subscribe((event: Event) => {
          if (event instanceof NavigationEnd) {
            this.growthbook.setURL(this.router.url);
            this.growthbook.updateAttributes({ url: this.router.url });
            this.growthbook.refreshFeatures();
          }
        });
    }
  }

  ngOnInit() {
    if (environment.production && isPlatformBrowser(this.platformId)) {
      this.listenToRoutes();
      this.initializeServerSideTracking();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private listenToRoutes() {
    // Google Tracking
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd && isPlatformBrowser(this.platformId)) {
        this.eventService.customNewPageEvent(this.storage?.user, item.url);
      }
    });
  }

  initializeServerSideTracking() {
    try {
      this.route.queryParams
        .pipe(takeUntil(this.destroy$))
        .subscribe((params) => {
          // ServerSide Tracking set AdClickIds to storage
          if (
            //this.storage.cookie_consent &&
            params &&
            !this.storage.authToken
          ) {
            Object.entries(params).forEach(([key, value]) => {
              if (this.adClickIds.includes(key)) {
                this.storage.adsClickObj = {
                  adsClickType: key,
                  adsClickValue: value,
                };
              }
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
}
