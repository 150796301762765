import {ExtraOptions, Routes} from '@angular/router';

import { environment } from '../../environments/handyhand/environment.dk';

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always'
};


/**
 HubPage
 */
export const mainCatRoutes: Routes = [
  {
    path: `:subCategory/tips-index`,
    loadComponent: () =>
      import('../pages/tips-index/tips-index.component').then(
        (c) => c.TipsIndexComponent
      ),
  },
  {
    path: `:subCategory/:subSubCategory/tips-index`,
    loadComponent: () =>
      import('../pages/tips-index/tips-index.component').then(
        (c) => c.TipsIndexComponent
      ),
  },
  {
    path: `tips-index`,
    loadComponent: () =>
      import('../pages/tips-index/tips-index.component').then(
        (c) => c.TipsIndexComponent
      ),
  },
  {
    path: `:subCategory/:subSubCategory/${environment.seo.hubIndex}`,
    loadComponent: () =>
      import('../pages/hub-page/hub-page.component').then(
        (c) => c.HubPageComponent,
      ),
    data: {
      isHubPage: true,
    },
  },
  {
    path: `:subCategory/${environment.seo.hubIndex}`,
    loadComponent: () =>
      import('../pages/hub-page/hub-page.component').then(
        (c) => c.HubPageComponent,
      ),
    data: {
      isHubPage: true,
    },
  },
  {
    path: environment.seo.hubIndex,
    loadComponent: () =>
      import('../pages/hub-page/hub-page.component').then(
        (c) => c.HubPageComponent,
      ),
    data: {
      isHubPage: true,
    },
  },
];

/**
 Verticals - City | Region
 */
export const cityRegionRoutes: Routes = [
  {
    path: `:region/${environment.seo.service}/${environment.seo.region}`,
    loadComponent: () =>
      import('../pages/seo/seo.component').then((c) => c.SeoComponent),
  },
  {
    path: `:region/:city/${environment.seo.service}/${environment.seo.city}`,
    loadComponent: () =>
      import('../pages/seo/seo.component').then((c) => c.SeoComponent),
  },
  {
    path: `${environment.seo.service}`,
    loadComponent: () =>
      import('../pages/seo/seo.component').then((c) => c.SeoComponent),
  },
  {
    path: '',
    loadComponent: () =>
      import('../components/shared/error/error.component').then(
        (m) => m.ErrorComponent,
      ),
  },
];
/**
 Verticals
 */
export const serviceRoutes: Routes = [
  {
    path: ':subCategory',
    data: {
      isFindPage: true,
    },
    children: cityRegionRoutes,
  },
  {
    path: '',
    data: {
      isFindPage: true,
    },
    children: cityRegionRoutes,
  },
];

/**
 * HowTo´s || PriceGuides
 */
export const guideRoutes: Routes = [
  {
    path: `:guideSlug/${environment.seo.article}`,
    loadComponent: () =>
      import('@app/pages/blog/details/blog.detail.component').then(
        (m) => m.BlogDetailComponent,
      ),
  },
  {
    path: `:guideSlug/${environment.seo.guide}`,
    loadComponent: () =>
      import('@app/pages/guides/howTos/howTo.component').then(
        (m) => m.HowToComponent,
      ),
  },
  {
    path: `:guideSlug/${environment.seo.inspiration}`,
    loadComponent: () =>
      import(
        '@app/pages/guides/inspirations/inspirations-details/inspirations-details.component'
      ).then((m) => m.InspirationsDetailsComponent),
  },
  {
    path: `:guideSlug/${environment.seo.priceGuide}`,
    loadComponent: () =>
      import('@app/pages/guides/priceGuides/priceGuide.component').then(
        (m) => m.PriceGuideComponent,
      ),
  },
  {
    path: `:guideSlug/${environment.seo.tips}`,
    loadComponent: () =>
      import('@app/pages/guides/tips/tips-details/tips-details.component').then(
        (m) => m.TipsDetailsComponent,
      ),
  },
];
export const mainGuideRoutes: Routes = [
  ...guideRoutes,
  {
    path: ':subCategory/:subSubCategory',
    data: {
      isFindPage: true,
    },
    children: [...cityRegionRoutes, ...guideRoutes],
  },
  /**
   * Routes under SubCategory
   * */
  {
    path: ':subCategory',
    children: [
      ...guideRoutes,
      {
        path: '',
        data: {
          isFindPage: true,
        },
        children: cityRegionRoutes,
      },
    ],
  },
];

export const seoRoutes: Routes = [
  {
    path: environment.seo.service,
    loadComponent: () =>
      import('../pages/seo/seo.component').then((c) => c.SeoComponent),
    data: {
      isFindPage: true,
    },
  },
  {
    path: '',
    children: [
      ...serviceRoutes,
      ...mainGuideRoutes,
      {
        path: '**',
        loadComponent: () =>
          import('../components/shared/error/error.component').then(
            (m) => m.ErrorComponent,
          ),
      },
    ],
  },
];
